import ContainerTitle from "../assets/ContainerTitle";
import Paper from "@mui/material/Paper";
import {
  useMediaQuery,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
} from "@mui/material";
import InnovationImage from "../images/innovation.png";
import MissionImage from "../images/mission.png";
import StrategyImage from "../images/strategy.jpg";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Button } from "@material-ui/core";
import pdf from "../business-documents/BUILDTRIX-COMPANY-PROFILE.pdf";

const cardDetails = [
  {
    id: 1,
    cardName: "Our Mission",
    desc: "Our mission is to ensure that our client’s goals are achieved by providing first class services to our Clients which ensure maximum value for money on their projects.",
    imageSrc: MissionImage,
  },
  {
    id: 2,
    cardName: "Vision and Innovation",
    desc: "Our vision is to become the pre-eminent specialist in the markets we serve. To inspire the innovator in everyone. To adapt most innovative solutions in the construction industry by ensuring maximum value for clients.",
    imageSrc: InnovationImage,
  },
  {
    id: 3,
    cardName: "Our Strategy",
    desc: "BuildTrix’s objective is to deliver optimal cost-effective solutions that fulfill the Client’s, Engineer’s and Local Authority’s specifications and requirements expeditiously.",
    imageSrc: StrategyImage,
  },
];

export default function AboutUs() {
  const isDesktop = useMediaQuery("(min-width:1200px)");

  return (
    <>
      <Paper
        style={{
          width: isDesktop ? "90%" : "75%",
          margin: "48px",
          padding: "0px 40px 40px 40px",
        }}
        elevation={2}
      >
        <div style={{ textAlign: "center" }}>
          <ContainerTitle
            style={{ marginLeft: isDesktop ? "8%" : "20%" }}
            title="About Us"
          />
          <div style={{ float: "right", marginTop: "30px" }}>
            <a
              style={{ textDecoration: "none" }}
              href={pdf}
              target="_blank"
              rel="noreferrer"
            >
              <Button>
                <PictureAsPdfIcon
                  style={{ color: "#f06859", marginRight: "5px" }}
                />{" "}
                View Brochure
              </Button>
            </a>
          </div>
        </div>

        <p>
          BuildTrix Design and Construction is a multi-disciplinary and dynamic
          project management company established to offer services within the
          built environment with outstanding Efficiency, Accuracy and Integrity
        </p>
        <p>
          This company is the brainchild of its founder, Mr. Sai Kiran, who set
          out to synergize his collective Contract Management and Quantity
          Surveying experience to provide dedicated and priceless expertise to
          Clients in both the Public and Private Sectors
        </p>
        <p>
          BDC, is run by professionals with a solid track record of Quantity
          Surveying, Construction Planning and Construction Management both at
          consultancy and construction levels. BDC acts in conjunction with the
          Property Development Managers, Architects, Consulting Engineers, other
          Professional Consultants and Contractors to safeguard the Client’s
          interest.
        </p>

        <p>
          BuildTrix is registered with the Govt. of Telangana and Micro, Small &
          Medium Enterprises (MSME, Govt. of India) as a Design and Construction
          Services with the ability to perform Construction activities and
          Professional Services.
        </p>

        <Grid
          container
          spacing={{ xs: 2, md: 6 }}
          columns={{ xs: 1, sm: 8, md: 12 }}
          justifyContent="center"
          style={{ marginTop: "30px" }}
        >
          {cardDetails.map((d, index) => {
            return (
              <Grid item key={index}>
                <Card
                  sx={{
                    maxWidth: isDesktop ? 400 : "auto",
                    maxHeight: 450,
                    minHeight: 450,
                  }}
                >
                  <CardMedia
                    component="img"
                    alt={d.cardName}
                    height="250"
                    image={d.imageSrc}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                      {d.cardName}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      <span className="card-desc-ellipsis">{d.desc}</span>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Paper>
    </>
  );
}
