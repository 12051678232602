import { useState, MouseEvent } from "react";
import {
  AppBar,
  Container,
  Toolbar,
  Typography,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MailIcon from "@mui/icons-material/Mail";
import logo from "../icons/HeaderLogo.png";

export default function AppHeader({
  appRefs,
}: {
  appRefs: {
    servicesRef: any;
    contactUsRef: any;
    pageTopRef: any;
    aboutUsRef: any;
  };
}) {
  const pages = [
    { page: "Services", linkedRef: appRefs.servicesRef },
    { page: "About Us", linkedRef: appRefs.aboutUsRef },
    { page: "Contact Us", linkedRef: appRefs.contactUsRef },
    { page: "Get a Quote", linkedRef: null },
  ];
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (e: any, ref: any) => {
    if (e.target.id === "Get a Quote") {
      window.open(
        "mailto:s.saikiran@outlook.com?subject=RE: Quote&body=%0D%0A Type of Construction: %0D%0A When do you Plan to Start: %0D%0A Let us know the current stage of the property: %0D%0A Land size: %0D%0A Land Description: %0D%0A Phone number: %0D%0A Email: %0D%0A Good time to reach:"
      );
    }
    setAnchorElNav(null);
    ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    ref.current.style.marginTop = "90px";
  };

  return (
    <AppBar className="app-header" position="fixed">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
          >
            <img
              style={{ cursor: "pointer" }}
              onClick={(e) => handleCloseNavMenu(e, appRefs.pageTopRef)}
              src={logo}
              alt="company logo"
              width="85px"
            ></img>
          </Typography>

          <Box
            sx={{
              flexGrow: 0.9,
              display: { xs: "flex", md: "none", color: "#353359" },
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon sx={{ fontSize: "40px" }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map(({ page, linkedRef }) => (
                <MenuItem
                  key={page}
                  onClick={(e) => handleCloseNavMenu(e, linkedRef)}
                >
                  <Typography fontSize="25px" textAlign="center">
                    {page === "Get a Quote" ? (
                      <div id={page} style={{ display: "flex" }}>
                        {page}
                        <MailIcon sx={{ fontSize: "40px" }} />
                      </div>
                    ) : (
                      page
                    )}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
          >
            <img
              style={{ cursor: "pointer" }}
              onClick={(e) => handleCloseNavMenu(e, appRefs.pageTopRef)}
              src={logo}
              alt="company logo"
              width="120px"
            ></img>
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map(({ page, linkedRef }) => (
              <Button
                key={page}
                id={page}
                onClick={(e) => handleCloseNavMenu(e, linkedRef)}
                sx={{ my: 2, color: "#353359", display: "block" }}
              >
                {page === "Get a Quote" ? (
                  <div
                    id={page}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <MailIcon sx={{ fontSize: "22px", marginRight: 1 }} />
                    {page}
                  </div>
                ) : (
                  page
                )}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
