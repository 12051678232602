export default function ContainerTitle({
  title,
  style,
}: {
  title: string;
  style?: any;
}) {
  return (
    <h1 style={style} className="container-title">
      {title}
    </h1>
  );
}
