import { useState } from "react";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
  Grid,
  useMediaQuery,
} from "@mui/material";
import DesignImage from "../images/design.png";
import ConstructionImage from "../images/construction.png";
import QualitySurveyingImage from "../images/quality-surveying.png";
import { ResponsiveModal } from "../assets/ResponsiveModal";
import ContainerTitle from "../assets/ContainerTitle";

export default function Services() {
  const isMobile = useMediaQuery("(max-width:900px)");
  const [showCardDetails, setShowCardDetails] = useState({
    show: false,
    title: "",
    content: "",
    customInfo: [],
  });

  const cardDetails = [
    {
      id: 1,
      cardName: "DESIGN",
      desc: "Buildtrix is a place to get all design solutions. We use latest available technology to provide you with unique and inspiring designs for various categories of projects. Technology enables us to provide you designs which are error free and at a competitive price. We ensure Optimum utilization of spaces based on customer requirement and simultaneously ensuring Vaastu norms compliance.",
      customInfo: undefined,
      imageSrc: DesignImage,
    },
    {
      id: 2,
      cardName: "CONSTRUCTION",
      desc: "We provide Individual House Construction, Villa Construction and Duplex/Triplex Home Construction services to cater to varied requirements of our clients. With our transparent pricing & quality norms, you know exactly what goes inside your home and how much does it cost you. Next best thing to expect apart from Quality & Transparent pricing is our on-time delivery commitment. With our project management & material tracking solution, you get your dream house built on time with high quality standards. Our team has relevant experience to ensure, you move-in and enjoy your dream home as per your requirement.",
      customInfo: undefined,
      imageSrc: ConstructionImage,
    },
    {
      id: 3,
      cardName: "QUANTITY SURVEYING",
      desc: "",
      customInfo: [
        {
          customTitle: "Pre Contract Services",
          customDesc: [
            "We provide Cost Estimate at various stages including Concept, Schematic and Detail Design stage to establish the Client Budget.",
            "Preparation of detailed Bills Of Quantities in line with the standard method of measurements to five the value for money.",
            "Assisting in Tender Services including invitation of tenders, Tender Evaluation, Negotiations, and Awarding the Contract.",
          ],
        },
        {
          customTitle: "Post Contract Services",
          customDesc: [
            "Contract Administration and Cost Monitoring",
            "Payment and Certification",
            "Variation / Change Management",
            "Extension Of Time",
          ],
        },
      ],
      imageSrc: QualitySurveyingImage,
    },
  ];

  const getCardDetail = (cardId: number) => {
    return cardDetails.find((detail) => detail.id === cardId);
  };

  const onClose = () => {
    setShowCardDetails({
      show: false,
      title: "",
      content: "",
      customInfo: [],
    });
  };

  return (
    <>
      <div className="services-container">
        <div style={{ textAlign: "center" }}>
          <ContainerTitle title="Services" />
        </div>
        <Grid
          container
          spacing={{ xs: 2, md: 6 }}
          columns={{ xs: 1, sm: 8, md: 12 }}
          justifyContent="center"
        >
          {cardDetails.map((d, index) => {
            return (
              <Grid item key={index}>
                <Card
                  sx={
                    isMobile
                      ? { margin: 6, maxHeight: 600, minHeight: 600 }
                      : { maxWidth: 400, maxHeight: 500, minHeight: 500 }
                  }
                >
                  <CardMedia
                    component="img"
                    alt={d.cardName}
                    height="250"
                    image={d.imageSrc}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                      {d.cardName}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {d.customInfo ? (
                        <>
                          <h4 style={{ margin: "5px 0px 5px 0px" }}>
                            {d.customInfo[0].customTitle}
                          </h4>
                          <li className="card-list-desc-ellipsis">
                            {d.customInfo[0].customDesc[0]}
                          </li>
                          <h4 style={{ margin: "5px 0px 5px 0px" }}>
                            {d.customInfo[1].customTitle}
                          </h4>
                          <li className="card-list-desc-ellipsis">
                            {d.customInfo[1].customDesc[1]}
                          </li>
                        </>
                      ) : (
                        <span className="card-desc-ellipsis">{d.desc}</span>
                      )}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      onClick={() => {
                        const cardDetail = getCardDetail(d.id);
                        setShowCardDetails({
                          show: true,
                          title: cardDetail?.cardName!,
                          content: cardDetail?.desc!,
                          customInfo: cardDetail?.customInfo! as [],
                        });
                      }}
                      style={{ fontSize: isMobile ? "25px" : "auto" }}
                      size="large"
                    >
                      Learn More
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
        </Grid>
        {showCardDetails.show && (
          <ResponsiveModal
            open={showCardDetails.show}
            close={onClose}
            details={showCardDetails}
          />
        )}
      </div>
    </>
  );
}
