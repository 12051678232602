import { Paper, useMediaQuery } from "@mui/material";
import { Form } from "./Form";
import { Header } from "./Header";
import { Social } from "./Social";

export default function ContactUs() {
  const isDesktop = useMediaQuery("(min-width:1200px)");
  return (
    <Paper
      style={{
        width: "85%",
        margin: "48px auto",
        padding: "35px",
      }}
      elevation={2}
    >
      <Header />
      <div
        style={{
          justifyContent: "space-evenly",
          display: isDesktop ? "flex" : "block",
        }}
      >
        <Social />
        <Form />
      </div>
    </Paper>
  );
}
