import {
  Facebook,
  Instagram,
  Twitter,
  PhoneInTalk,
  LocationOn,
  Email,
} from "@mui/icons-material";
import { useMediaQuery } from "@mui/material";

export function Social() {
  const isDesktop = useMediaQuery("(min-width:1200px)");

  return (
    <>
      <div
        style={{
          width: isDesktop ? "40%" : "auto",
          marginTop: "60px",
        }}
      >
        <div
          className="d-flex"
          style={{
            fontSize: isDesktop ? "" : "x-large",
            alignItems: isDesktop ? "" : "end",
          }}
        >
          <PhoneInTalk className="icon-style" />
          <a href="tel:+91 94406 88822">+91 94406 88822</a>
        </div>
        <br />
        <div
          className="d-flex"
          style={{
            fontSize: isDesktop ? "" : "x-large",
            alignItems: isDesktop ? "" : "end",
          }}
        >
          <Email className="icon-style" />
          <a
            target="_blank"
            rel="noreferrer"
            href="mailto:saikiran.qs@gmail.com"
          >
            saikiran.qs@gmail.com
          </a>
        </div>
        <br />

        <div className="d-flex">
          <LocationOn className="icon-style" />
          <b style={{ fontSize: isDesktop ? "" : "large" }}>MAIN BRANCH:</b>
        </div>
        <a
          href="https://goo.gl/maps/hQruQjqe7kJDbhk79"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div
            className="address-location"
            style={{
              marginBottom: "10px",
              fontSize: isDesktop ? "" : "small",
            }}
          >
            15-21-1/107, APHB Colony, Balajinagar, Kukatpally, Hyd, Telangana
            500072
          </div>
        </a>
        <div style={{ marginTop: "30px" }} className="d-flex">
          <LocationOn className="icon-style" />
          <b style={{ fontSize: isDesktop ? "" : "large" }}>BRANCH OFFICE:</b>
        </div>
        <a
          href="https://goo.gl/maps/PJ8L79Wb1dYCUVUN8"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div
            className="address-location"
            style={{ fontSize: isDesktop ? "" : "small" }}
          >
            Green Meadows Colony Rd, Raghavendra Colony, Hyd, Telangana 502319.
          </div>
        </a>
        <div style={{ margin: "40px" }} />
        <div className="d-flex">
          <a
            href="https://www.facebook.com/buildtrix/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Facebook
              style={{
                color: "#f06859",
                fontSize: isDesktop ? "xxx-large" : "75px",
              }}
              sx={{
                "&:hover": {
                  transform: "scale(1.2)",
                },
              }}
            />
          </a>
          <a
            href="https://www.instagram.com/buildtrix/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Instagram
              style={{
                color: "#f06859",
                fontSize: isDesktop ? "xxx-large" : "75px",
                margin: "0px 10px",
              }}
              sx={{
                "&:hover": {
                  transform: "scale(1.2)",
                },
              }}
            />
          </a>
          <a
            href="https://www.twitter.com/buildtrix/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Twitter
              style={{
                color: "#f06859",
                fontSize: isDesktop ? "xxx-large" : "75px",
              }}
              sx={{
                "&:hover": {
                  transform: "scale(1.2)",
                },
              }}
            />
          </a>
        </div>
      </div>
    </>
  );
}
