import ContainerTitle from "../../assets/ContainerTitle";

export function Header() {
  return (
    <div style={{ textAlign: "center" }}>
      <ContainerTitle title="CONTACT US" />
      <p
        style={{
          margin: "20px",
        }}
      >
        Are you searching for an experienced general construction partner who
        values quality, collaboration, and demonstrates integrity? We want to
        work with you. Together, we will bring your vision to life.
      </p>
    </div>
  );
}
