import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  useMediaQuery,
} from "@mui/material";

type Props = {
  open: boolean;
  close: () => void;
  details: {
    title: string | undefined;
    content: string | undefined;
    customInfo: any;
  };
};

export function ResponsiveModal({ open, close, details }: Props) {
  const isMobile = useMediaQuery("(max-width:900px)");
  const { title, content, customInfo } = details;
  return (
    <Dialog
      className="services-detailed-info-modal"
      open={open}
      onClose={close}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
      <DialogContent dividers>
        {!customInfo ? (
          <DialogContentText fontSize={isMobile ? "30px" : "auto"}>
            {content}
          </DialogContentText>
        ) : (
          customInfo.map((info: any) => {
            return (
              <>
                <h3>{info.customTitle}</h3>
                <DialogContentText fontSize={isMobile ? "30px" : "auto"}>
                  <ul>
                    {info.customDesc.map((line: string) => {
                      return <li>{line}</li>;
                    })}
                  </ul>
                </DialogContentText>
              </>
            );
          })
        )}
      </DialogContent>
      <DialogActions>
        <Button
          style={{ fontSize: isMobile ? "25px" : "auto" }}
          size="large"
          onClick={close}
          autoFocus
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
