import { useMediaQuery } from "@mui/material";
import desktopConstructionVideo from "../icons/constructionVideo.mp4";
import mobileConstructionVideo from "../icons/mobileConstructionVideo.mp4";

export default function ConstructionVideo() {
  const isMobile = useMediaQuery("(max-width:900px)");
  return (
    <div className="construction-video">
      <video
        autoPlay
        loop
        muted
        playsInline
        style={{ width: "100%", borderRadius: "10px" }}
        src={isMobile ? mobileConstructionVideo : desktopConstructionVideo}
      ></video>
      <div className="content">
        <h2 style={{ marginBottom: 0 }}>
          LEADERS IN QUALITY CONSTRUCTION AND INFRASTRUCTURE
        </h2>
      </div>
    </div>
  );
}
