import {
  TextField,
  FormControl,
  useMediaQuery,
  Alert,
  Snackbar,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { MouseEvent, useState, useRef, ChangeEvent } from "react";
import emailjs from "@emailjs/browser";
import SendIcon from "@mui/icons-material/Send";

type FormState = {
  name: string;
  email: string;
  phoneNumber: number | undefined;
  message: string;
};

const emailStatusInitialState = {
  submitting: false,
  error: false,
  sent: false,
};

export function Form() {
  const isDesktop = useMediaQuery("(min-width:1200px)");
  const [formState, setFormState] = useState<FormState>({
    name: "",
    email: "",
    phoneNumber: undefined,
    message: "",
  });
  const [emailStatus, setEmailStatus] = useState(emailStatusInitialState);

  const form = useRef({});

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const sendEmail = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setEmailStatus({ ...emailStatus, submitting: true });
    emailjs
      .sendForm(
        "service_srconstructions",
        "template_8bl5bwk",
        form.current as string,
        "user_CTRUASEoQMk20GmOA0yCB"
      )
      .then((result) => {
        setEmailStatus({ ...emailStatus, submitting: false, sent: true });
        setTimeout(() => setEmailStatus(emailStatusInitialState), 6000);
      })
      .catch((error) => {
        setEmailStatus({ ...emailStatus, submitting: false, error: true });
        setTimeout(() => setEmailStatus(emailStatusInitialState), 6000);
      });
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    event.preventDefault();
    if (reason === "clickaway") {
      return;
    }
    setEmailStatus(emailStatusInitialState);
  };

  const getAlertMessage = () => {
    if (emailStatus.sent) {
      return (
        <div>
          We received your request. One of our associate's will get in touch
          with you as soon as possible.
        </div>
      );
    }
    return (
      <div>
        There is an error submitting your request. Please try again later or
        call our direct line at{" "}
        <a href="tel:+91 94406 88822"> +91 94406 88822</a>
      </div>
    );
  };

  return (
    <>
      <form
        ref={form as any}
        onSubmit={sendEmail as any}
        style={{
          width: isDesktop ? "40%" : "auto",
          marginTop: isDesktop ? "" : "30px",
        }}
      >
        <FormControl variant="outlined" fullWidth sx={{ m: 1 }}>
          <TextField
            id="outlined-name"
            label="Name"
            name="name"
            variant="outlined"
            value={formState.name}
            onChange={onChange}
            disabled={emailStatus.submitting}
          />
        </FormControl>
        <FormControl variant="outlined" fullWidth sx={{ m: 1 }}>
          <TextField
            id="outlined-email"
            label="Email"
            name="email"
            variant="outlined"
            value={formState.email}
            onChange={onChange}
            disabled={emailStatus.submitting}
          />
        </FormControl>
        <FormControl variant="outlined" fullWidth sx={{ m: 1 }}>
          <TextField
            multiline
            rows={6}
            name="message"
            id="outlined-message"
            label="Message"
            variant="outlined"
            value={formState.message}
            onChange={onChange}
            disabled={emailStatus.submitting}
          />
        </FormControl>
        <FormControl variant="outlined" fullWidth sx={{ m: 1 }}>
          <TextField
            type="number"
            name="phoneNumber"
            label="Phone Number"
            variant="outlined"
            value={formState.phoneNumber}
            onChange={onChange}
            disabled={emailStatus.submitting}
          />
        </FormControl>
        <LoadingButton
          fullWidth
          loading={emailStatus.submitting}
          loadingPosition="end"
          type="submit"
          variant="contained"
          style={{ margin: "8px" }}
          endIcon={<SendIcon />}
          disabled={!(formState.email && formState.message && formState.name)}
        >
          Send
        </LoadingButton>
      </form>
      <Snackbar open={emailStatus.error || emailStatus.sent}>
        <Alert
          onClose={handleClose}
          severity={emailStatus.error ? "error" : "success"}
        >
          {getAlertMessage()}
        </Alert>
      </Snackbar>
    </>
  );
}
