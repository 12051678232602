import { useEffect, useRef } from "react";
import AppHeader from "./components/AppHeader";
import ConstructionVideo from "./components/ConstructionVideo";
import Services from "./components/Services";
import ContactUs from "./components/contact";
import AppFooter from "./components/AppFooter";
import AboutUs from "./components/AboutUs";

import "react-image-gallery/styles/css/image-gallery.css";

import "./App.css";

export default function App() {
  const pageTopRef = useRef(null);
  const servicesRef = useRef(null);
  const contactUsRef = useRef(null);
  const aboutUsRef = useRef(null);

  const scrollToTop = (ref: any) => {
    ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    ref.current.style.marginTop = "90px";
  };

  useEffect(() => {
    scrollToTop(pageTopRef);
  }, []);

  return (
    <>
      <AppHeader
        appRefs={{ servicesRef, contactUsRef, pageTopRef, aboutUsRef }}
      />

      <div ref={pageTopRef} />
      <ConstructionVideo />

      <div ref={servicesRef} />
      <Services />

      <div ref={aboutUsRef} />
      <AboutUs />

      <div ref={contactUsRef} />
      <ContactUs />

      <AppFooter />
    </>
  );
}
